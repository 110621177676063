import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import CustomInput from "components/CustomInput";
import Button from "components/CustomButtons";
import SearchSideBar from "components/SearchSideBar";
import CompanyList from "./CompanyList";
import TrialList from "./TrialList";

import axios from "axios";
import qs from "qs";
const FormData = require('form-data')
import { api } from 'config.js';
const path = require('path');

const styles = {
    result: {
        flex: 1,
        marginLeft: "auto",
        marginRight: "auto",
        width: "100%",
    }
};

class SearchContent extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        let { classes, searchBy, ...rest } = this.props;

        let searchResults = <div />;
        if (searchBy == "company") {
            searchResults = <CompanyList searchBy={searchBy} {...rest} />;
        } else {
            searchResults = <TrialList searchBy={searchBy} {...rest} />;
        }

        return (<div className={classes.result}>
            <GridContainer>
                <GridItem xs={12} sm={12} md={1}>
                </GridItem>
                <GridItem xs={12} sm={12} md={3}>
                    <SearchSideBar searchBy={searchBy} {...rest} />
                </GridItem>
                <GridItem xs={12} sm={12} md={7}>
                    {searchResults}
                </GridItem>
                <GridItem xs={12} sm={12} md={1}>
                </GridItem>
            </GridContainer>
        </div>);
    }
}

export default withStyles(styles)(SearchContent);
