import React from 'react';
import classNames from "classnames";
import { alpha, makeStyles } from '@material-ui/core/styles';
import withStyles from "@material-ui/core/styles/withStyles";
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import InputBase from '@material-ui/core/InputBase';
import Badge from '@material-ui/core/Badge';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import MenuIcon from '@material-ui/icons/Menu';
import SearchIcon from '@material-ui/icons/Search';
import AccountCircle from '@material-ui/icons/AccountCircle';
import MailIcon from '@material-ui/icons/Mail';
import NotificationsIcon from '@material-ui/icons/Notifications';
import MoreIcon from '@material-ui/icons/MoreVert';
import CustomInput from "components/CustomInput";

import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

import { title } from "config.js";

const configTitle = title;

const useStyles = (theme) => ({
  appBar: {
    backgroundColor: "primary",
    boxShadow: "none",
    width: "100%",
    zIndex: "1029",
    color: "#FFFFFF",
    padding: "0 0",
    transition: "all 150ms ease 0s",
    display: "block"
  },
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(3),
  },
  title: {
    display: 'none',
    color: 'white',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 100,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
    fullWidth: true, // TODO: fix this
  },
  formControl: {
    margin: theme.spacing(1),
  },
  selectEmpty: {
    marginTop: theme.spacing(0),
    color: 'white',
    minWidth: theme.spacing(10),
  },
});

function PrimarySearchAppBar({ ...props }) {
  const { classes, color, handleSearchQueryChange, handleSearchQueryFinished, handleSearchByChange, searchBy, currentValue } = props;

  console.log("title: ", configTitle);

  const appBarClasses = classNames({
    [" " + classes[color]]: color
  });

  const confirmEnter = (e) => {
    if (e.key === 'Enter') {
      handleSearchQueryFinished(e);
    }
  }

  return (
    <div className={classes.grow}>
      <AppBar position="static" className={classes.appBar + appBarClasses}>
        <Toolbar>
          <IconButton
            edge="start"
            className={classes.menuButton}
            color="inherit"
            aria-label="open drawer"
          >
            {/* menu icon here */}
          </IconButton>
          <a href='/'>
            <Typography className={classes.title} variant="h6" noWrap>
              {configTitle}
            </Typography>
          </a>
          <div className={classes.grow} />
          <div className={classes.search}>
            <div className={classes.searchIcon}>
              <SearchIcon />
            </div>
            <InputBase
              placeholder="Search…"
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput,
              }}
              inputProps={{
                onChange: handleSearchQueryChange,
                onKeyPress: confirmEnter,
                defaultValue: currentValue,
                'aria-label': 'search'
              }}
            />
          </div>
          <FormControl variant="outlined" className={classes.formControl}>
            <Select
              value={searchBy}
              onChange={handleSearchByChange}
              displayEmpty
              className={classes.selectEmpty}
            >
              <MenuItem value="trial">Trials</MenuItem>
              <MenuItem value="company">Companies</MenuItem>
            </Select>
          </FormControl>
          <div className={classes.grow} />
        </Toolbar>
      </AppBar>
    </div>
  );
}

export default withStyles(useStyles)(PrimarySearchAppBar);
