// this file is a wrapper to use REST API easier
// examples: https://github.com/axios/axios/tree/v2.x/examples
import axios from "axios";
import { GetCookie, SetCookie, RemoveCookie } from './cookie';
import { api } from 'config.js';

const kAuthJWTName = 'jwt-auth'
const path = require('path');

class Rest {
  setAuth(token) {
    SetCookie(kAuthJWTName, token)
  }

  getAuth() {
    return GetCookie(kAuthJWTName)
  }

  removeAuth() {
    RemoveCookie(kAuthJWTName)
  }

  withAuthHeader(headers = {}) {
    if (!headers) {
      headers = {}
    }
    const auth = this.getAuth()
    if (auth) {
      headers['Authorization'] = auth
    }
    return headers
  }

  withJsonHeader(headers = {}) {
    if (!headers) {
      headers = {}
    }
    headers['Content-Type'] = 'application/json'
    return headers
  }

  buildOptions(params = {}, headers = {}) {
    let options = {}

    if (params) {
      options.params = params
    }

    options.headers = this.withAuthHeader(headers)

    return options
  }

  concatUrl(url) {
    // let api_base = this.api_base;
    // let newUrl = `${api_base}${url}`;
    let newUrl = `${api.host}${path.join(api.path, url)}`
    return newUrl
  }

  async perform(func, url, params = {}, headers = {}) {
    let newUrl = this.concatUrl(url);
    console.log("target url: %o, params: %o ", newUrl, params, headers)
    let options = this.buildOptions(params, headers)
    try {
      let ret = await func(newUrl, options)
      return ret

      // if we need to request a sequence data:
      // const ret1p = axios.get(newUrl1);
      // const ret2p = axios.get(newUrl2);
      // const ret3p = axios.get(newUrl3);
      // const [ret1, ret2, ret3] = await Promise.all([ret1p, ret2p, ret3p]);
    } catch (e) {
      console.error(e);
      return { "status": false, "message": e }
    }
  }

  get(url, params = {}, headers = {}) {
    return this.perform(function (newUrl, options) { return axios.get(newUrl, options) }, url, params, headers)
  }

  post(url, data, params = {}, headers = {}) {
    return this.perform(function (newUrl, options) { return axios.post(newUrl, data, options) }, url, params, headers)
  }

  postJson(url, params = {}, headers = {}) {
    headers = this.withJsonHeader(headers)
    return this.post(url, params, headers)
  }

  put(url, data, params = {}, headers = {}) {
    return this.perform(function (newUrl, options) { return axios.put(newUrl, data, options) }, url, params, headers)
  }

  track(params = {}) {
    this.get("tracker/collect", params)
  }

  async checkSelf() {
    let ret = await this.get('auth/me')
    if (ret.status === 200) {
      return true
    } else {
      console.error("not logged in: %o", ret)
      return false
    }
  }
}

const RestInstance = new Rest()

export default RestInstance;
