// This is a configuration file
// let debug = false;
let debug = true;

let api = {
    // https://jsonplaceholder.typicode.com/ is a example api
    host: "",
    path: "/",
    timeout: 15000, // timeout in MS
};

// see webpack.dev.js for more info
if (process.env.NODE_ENV === "development") {
    api.host = "https://trialmind.ai" // 
    api.path = "/"
}

module.exports = ({
    title: "Clinical Trial Portal",
    author: "Sunlab.ORG",
    homepage: "/",
    description: "Make clinical trial data more accessible",
    api: api,
    debug: debug,
})
