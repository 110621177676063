import React from "react";
import classNames from "classnames";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// @material-ui/icons
import Code from "@material-ui/icons/Code";
import Cloud from "@material-ui/icons/Cloud";
import Polymer from "@material-ui/icons/Polymer";
// core components
import CommandForm from "components/CommandForm";
import CustomTabs from "components/CustomTabs";
import JumpMaker from "components/JumpMaker";
import SearchBox from "components/SearchBox";
import Header from "components/Header/index";
import Footer from "components/Footer";
import Content  from "./Content";

import searchStyle from "assets/jss/material-dashboard-react/layouts/searchStyle.jsx";

import { api } from 'config.js';
import { title } from "config.js";
import qs from "qs";
import { Redirect } from "react-router";
import axios from "axios";
const path = require('path');

const mySearchStyle = searchStyle;

class SearchPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sponsors: [],
      trials: [],
      total: 0,
      message: "",
    };
  }

  concatUrl(url) {
    // let api_base = this.api_base;
    // let newUrl = `${api_base}${url}`;
    let newUrl = `${api.host}${path.join(api.path, url)}`
    console.log("concat url: %o", newUrl)
    return newUrl
  }

  componentDidMount() {
    // this.updateResult(this.props);
  }

  updateResult = (props, query = "", by = "") => {
    if (query.length === 0 || by.length === 0) {
      let parsedQS = qs.parse(props.location.search, { ignoreQueryPrefix: true });
      query = parsedQS.qs;
      by = parsedQS.by;
    }
    let request_url = "";
    if (by == "company") {
      request_url = this.concatUrl("/api/v1/sponsors:search");
    } else {
      request_url = this.concatUrl("/api/v1/trials:search");
    }
    let queryPart = qs.stringify({ qs: query });
    request_url = `${request_url}?${queryPart}`;
    console.log("request_url: %o", request_url);
    axios({
      method: 'GET',
      url: request_url,
      transformResponse: [(response) => (JSON.parse(response))],
    }).then((response) => {
      console.log("response: %o", response);
      if (response.data) {
        let total = response.data.data.total;
        let sponsors = response.data.data.sponsors;
        let trials = response.data.data.trials;
        console.log("total: %o, sponsors: %o, trials: %o || %o", total, sponsors, trials, response.data);
        this.setState({
          sponsors: sponsors,
          trials: trials,
          total: total,
        });
      }
    }).catch((error) => {
      console.log("got error: %o", error);
      // is data in error.response.data?
      this.setState({
        message: error,
      });
    });
  }

  handleSearchQueryChange = (event) => {
    // return this.handleChange(event.target.value, this.state.slug);
    console.log("SearchPage: handleSearchQueryChange: %o", event.target.value);
  }

  handleSearchQueryFinished = (event) => {
    // return this.handleChange(event.target.value, this.state.slug);
    console.log("SearchPage: handleSearchQueryFinished: %o", event.target.value);
    let newQuery = event.target.value;
    let parsedQS = qs.parse(this.props.location.search, { ignoreQueryPrefix: true });
    let prevQuery = parsedQS.qs;
    if (newQuery.length === 0) {
      console.log("SearchPage: no query");
      return;
    }
    if (newQuery === prevQuery) {
      console.log("SearchPage: same query");
      return;
    }
    let queryPart = qs.stringify({ qs: newQuery, by: parsedQS.by });
    let url = "/search?" + queryPart;
    console.log("url: %o", url);
    this.props.history.push(url);
    // this.updateResult(this.props, newQuery, parsedQS.by);
  }

  render() {
    // const { classes } = this.props;
    const { classes, color, baseUrl } = this.props;

    let parsedQS = qs.parse(this.props.location.search, { ignoreQueryPrefix: true });

    const containerClasses = classNames({
      [" " + classes[color]]: color
    });

    if (!(parsedQS.by == "company" || parsedQS.by == "trial")) {
      parsedQS.by = "company";
    }

    return (<div className={classes.wrapper}>
      <div className={classes.mainPanel} ref="mainPanel">
        {/* Header */}
        <Header
          handleSearchQueryChange={this.handleSearchQueryChange}
          handleSearchQueryFinished={this.handleSearchQueryFinished}
          currentValue={parsedQS.qs}
        />
        {/* Content */}
        <div className={classes.content}>
          <Content 
            className={classes.container} 
            searchBy={parsedQS.by} 
            sponsors={this.state.sponsors} 
            trials={this.state.trials}
            total={this.total} />
        </div>
        {/* Footer */}
        <Footer />
      </div>
    </div>);
  }
}

export default withStyles(mySearchStyle)(SearchPage);

