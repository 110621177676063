import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// @material-ui/icons
import Store from "@material-ui/icons/Store";
import InfoIcon from "@material-ui/icons/Info";
import Warning from "@material-ui/icons/Warning";
import DateRange from "@material-ui/icons/DateRange";
import LocalOffer from "@material-ui/icons/LocalOffer";
import Update from "@material-ui/icons/Update";
import ArrowUpward from "@material-ui/icons/ArrowUpward";
import AccessTime from "@material-ui/icons/AccessTime";
import Accessibility from "@material-ui/icons/Accessibility";
import BugReport from "@material-ui/icons/BugReport";
import Code from "@material-ui/icons/Code";
import Cloud from "@material-ui/icons/Cloud";
// core components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import Table from "components/Table";
import Danger from "components/Typography/Danger.jsx";
import Card from "components/Card";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardFooter from "components/Card/CardFooter.jsx";
import Info from "components/Typography/Info.jsx";


const styles = {
    typo: {
        paddingLeft: "25%",
        marginBottom: "40px",
        position: "relative"
    },
    note: {
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        bottom: "10px",
        color: "#c0c1c2",
        display: "block",
        fontWeight: "400",
        fontSize: "13px",
        lineHeight: "13px",
        left: "0",
        marginLeft: "20px",
        position: "absolute",
        width: "260px"
    },
    cardCategory: {
        color: "#999999",
        margin: "0",
        fontSize: "14px",
        marginTop: "0",
        paddingTop: "10px",
        marginBottom: "0"
    },
    cardCategoryWhite: {
        color: "rgba(255,255,255,.62)",
        margin: "0",
        fontSize: "14px",
        marginTop: "0",
        marginBottom: "0"
    },
    cardNCTID: {
        color: "#999999",
        margin: "0",
        // bold and italic
        fontStyle: "italic",
    },
    cardTitle: {
        color: "#3C4858",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        textAlign: 'left',
        "& small": {
            color: "#777",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
    cardTagItem: {
        justifyContent: 'space-between',
        color: "#3C4858",
        marginTop: "0px",
        paddingRight: "5px",
        minHeight: "auto",
        fontWeight: "100",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        textAlign: 'center',
        display: 'inline-block',
        "& small": {
            color: "#777",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
    cardIcon: {
        width: "15px",
        height: "15px",
        color: "#ac92ec",
    },
};

function TrialList(props) {
    const { classes, searchBy, trials, total } = props;

    if (searchBy != 'trial') {
        console.log("search by : %o", searchBy)
        return <div />
    }

    if (trials == null) {
        console.log("trials is null")
        return <div />
    }

    const tableData = trials.map((trial) => {
        let dz_group = trial.dz_group.map((dz) => {
            return <div key={trial.id + "_" + "dz" + "_" + dz} className={classes.cardTagItem} >
                <LocalOffer className={classes.cardIcon} /> {dz}
            </div>
        })

        let criteria = trial.criteria
        // limit criteria length
        if (criteria.length > 400) {
            criteria = criteria.slice(0, 400);
            criteria += "..."
        }

        let why_stop = trial.why_stop
        // trim why_stop space
        if (why_stop != null) {
            why_stop = why_stop.trim()
        }

        if (why_stop != null && why_stop.length > 0) {
            why_stop = <Info > <InfoIcon className={classes.cardIcon} /> <b>Why Stop:</b> {why_stop} </Info>
        } else {
            why_stop = <div />
        }

        return <Card key={trial.id}>
            <CardHeader color="info" stats icon>
                <p className={classes.cardCategory}>{trial.status}</p>
                <h3 className={classes.cardTitle}>
                    <a href={"https://clinicaltrials.gov/ct2/show/study/" + trial.nctid}>
                        <span className={classes.cardNCTID}>{trial.nctid} :</span> {trial.brief_title}
                    </a>
                </h3>
            </CardHeader>
            <CardBody>
                <b>{trial.official_title}</b>: {criteria}
                {why_stop}
            </CardBody>
            <CardFooter stats>
                <div className={classes.stats}>
                    <div>{dz_group}</div>
                </div>
                <div className={classes.stats}>
                    <div>{trial.sponsor}</div>
                </div>
            </CardFooter>
        </Card >
    });

    return (
        <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
                {tableData}
            </GridItem>
        </GridContainer>
    );
}

export default withStyles(styles)(TrialList);
