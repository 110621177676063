import React from "react";
import classNames from "classnames";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// @material-ui/icons
// core components
import SearchContent from "components/SearchContent";

import Header from "components/Header/index";
import Footer from "components/Footer";

import searchStyle from "assets/jss/material-dashboard-react/layouts/searchStyle.jsx";

import { api } from 'config.js';
import qs from "qs";
import axios from "axios";
const path = require('path');

const mySearchStyle = searchStyle;

class SearchPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sponsors: [],
      trials: [],
      aggs: {},
      total: 0,
      message: "",
    };
  }

  concatUrl(url) {
    let newUrl = `${api.host}${path.join(api.path, url)}`
    console.log("concat url: %o", newUrl)
    return newUrl
  }

  componentDidMount() {
    this.updateResult(this.props);
  }

  updateResult = (props, parsedQS = {}) => {
    let prevParsedQS = qs.parse(props.location.search, { ignoreQueryPrefix: true });
    // if (parsedQS.qs == null || parsedQS.qs.length === 0) {
    //   parsedQS.qs = prevParsedQS.qs;
    // }
    // if (parsedQS.by == null || parsedQS.by.length === 0) {
    //   parsedQS.by = prevParsedQS.by;
    // }
    if (Object.keys(parsedQS).length === 0) {
      console.log("parsedQS is empty, use prevParsedQS");
      parsedQS = prevParsedQS;
    }
    console.log("parsedQS: %o, prevParsedQS: %o", parsedQS, prevParsedQS);
    let request_url = "";

    if (parsedQS.by == "trial") {
      request_url = this.concatUrl("/api/v1/trials:search");
    } else {
      request_url = this.concatUrl("/api/v1/sponsors:search");
    }

    console.log("parsedQS: %o", parsedQS);
    let queryPart = qs.stringify(parsedQS);
    console.log("queryPart: %o", queryPart);
    request_url = `${request_url}?${queryPart}`;
    console.log("request_url: %o", request_url);
    axios({
      method: 'GET',
      url: request_url,
      transformResponse: [(response) => (JSON.parse(response))],
    }).then((response) => {
      console.log("response: %o", response);
      if (response.data) {
        let total = response.data.data.total;
        let sponsors = response.data.data.sponsors;
        let trials = response.data.data.trials;
        let aggs = response.data.data.aggs;
        console.log("total: %o, sponsors: %o, trials: %o || %o", total, sponsors, trials, response.data);
        this.setState({
          sponsors: sponsors,
          trials: trials,
          total: total,
          aggs: aggs,
        });
      }
    }).catch((error) => {
      console.log("got error: %o", error);
      // is data in error.response.data?
      this.setState({
        message: error,
      });
    });
  }

  handleQueryChangeEvent = (newQuery, newBy, params = {}) => {
    newQuery = newQuery.trim();
    newBy = newBy.trim();
    let parsedQS = qs.parse(this.props.location.search, { ignoreQueryPrefix: true });
    let prevQuery = parsedQS.qs;
    let prevBy = parsedQS.by;
    if (newQuery.length === 0) {
      newQuery = prevQuery;
    }
    if (newBy.length === 0) {
      newBy = prevBy;
    }
    if (newQuery === prevQuery && newBy === prevBy && Object.keys(params).length === 0) {
      console.log("SearchPage: no change");
      return;
    }
    // copy params
    let newParams = Object.assign({}, params);
    newParams.qs = newQuery;
    newParams.by = newBy;
    console.log("SearchPage: handleQueryChangeEvent: %o", newParams);
    let queryPart = qs.stringify(newParams);
    let url = "/search?" + queryPart;
    console.log("url: %o", url);
    this.props.history.push(url);
    this.updateResult(this.props, newParams);
  }

  handleSearchQueryChange = (event) => { // do nothing for now
    console.log("SearchPage: handleSearchQueryChange: %o", event.target.value);
  }

  handleSearchQueryFinished = (event) => {
    console.log("SearchPage: handleSearchQueryFinished: %o", event.target.value);
    let newQuery = event.target.value;
    this.handleQueryChangeEvent(newQuery, ""); // "" means no change
  }

  handleSearchByChange = (event) => {
    console.log("SearchPage: handleSearchQueryFinished: %o", event.target.value);
    let newBy = event.target.value;
    this.handleQueryChangeEvent("", newBy); // "" means no change
  }

  handleFilterEventChange = (category, key, active) => {
    console.log("handleFilterEventChange: %o :: %o", category, key, active);
    let parsedQS = qs.parse(this.props.location.search, { ignoreQueryPrefix: true });
    switch (category) {
      case "dz_group":
        if (active) {
          parsedQS.dz_group = key;
        } else {
          delete parsedQS.dz_group;
        }
        this.handleQueryChangeEvent("", "", parsedQS);
        break;
    }
  }

  render() {
    // const { classes } = this.props;
    const { classes, color, baseUrl } = this.props;

    let parsedQS = qs.parse(this.props.location.search, { ignoreQueryPrefix: true });

    const containerClasses = classNames({
      [" " + classes[color]]: color
    });

    if (!(parsedQS.by == "company" || parsedQS.by == "trial")) {
      parsedQS.by = "company";
    }

    console.log("SearchPage: parsedQS: %o @ %o", parsedQS.qs, parsedQS.by);

    console.log("search by %o, sponsors: %o, trials: %o", parsedQS.by, this.state.sponsors, this.state.trials)
    
    return (<div className={classes.wrapper}>
      <div className={classes.mainPanel} ref="mainPanel">
        {/* Header */}
        <Header
          handleSearchQueryChange={this.handleSearchQueryChange}
          handleSearchQueryFinished={this.handleSearchQueryFinished}
          handleSearchByChange={this.handleSearchByChange}
          parsedQS={parsedQS}
          searchBy={parsedQS.by}
          currentValue={parsedQS.qs}
        />
        {/* Content */}
        <div className={classes.content}>
          <SearchContent
            className={classes.container}
            parsedQS={parsedQS}
            searchBy={parsedQS.by}
            handleFilterEventChange={this.handleFilterEventChange}
            sponsors={this.state.sponsors}
            trials={this.state.trials}
            aggs={this.state.aggs}
            total={this.total} />
        </div>
        {/* Footer */}
        <Footer />
      </div>
    </div>);
  }
}

export default withStyles(mySearchStyle)(SearchPage);

