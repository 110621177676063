import React from "react";
import PropTypes from "prop-types";
// react plugin for creating charts
import ChartistGraph from "react-chartist";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import TablePagination from '@material-ui/core/TablePagination';
import TableSortLabel from '@material-ui/core/TableSortLabel';
// core components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import Table from "components/Table";
import Quote from "components/Typography/Quote.jsx";
import Muted from "components/Typography/Muted.jsx";
import Primary from "components/Typography/Primary.jsx";
import Info from "components/Typography/Info.jsx";
import Success from "components/Typography/Success.jsx";
import Warning from "components/Typography/Warning.jsx";
import Danger from "components/Typography/Danger.jsx";
import Card from "components/Card";
import CardHeader from "components/Card/CardHeader.jsx";
import CardAvatar from "components/Card/CardAvatar.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardFooter from "components/Card/CardFooter.jsx";
import TablePaginationActions from "components/Table/TablePaginationActions.jsx";

import api from "components/Request/api";

import avatar from "assets/img/reactlogo.png";

import {
  dailySalesChart,
  emailsSubscriptionChart,
  completedTasksChart
} from "variables/charts.jsx";

const style = {
  typo: {
    paddingLeft: "25%",
    marginBottom: "40px",
    position: "relative"
  },
  note: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    bottom: "10px",
    color: "#c0c1c2",
    display: "block",
    fontWeight: "400",
    fontSize: "13px",
    lineHeight: "13px",
    left: "0",
    marginLeft: "20px",
    position: "absolute",
    width: "260px"
  },
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0"
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none"
  }
};

const trial_header_names = [
  "NCTID",
  "Brief Title",
  "Date",
  "Status",
]

const trial_header_order_by_keys = [
  "nctid",
  "brief_title",
  "date",
  "status",
]

class CompanyDetailPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      trial_headers: this.createHeaders(trial_header_names, -1, 'asc'),
      trial_data: [],
      trial_order: 'asc',
      trial_order_by: -1,
      page: 0,
      rowsPerPage: 5,
      trial_count: 0,
      rowsPerPageOptions: [5, 10, 20, 50],
      stats: {
        labels: [],
        series: [],
      },
      stats_options: {
        lineSmooth: Chartist.Interpolation.cardinal({
          tension: 0
        }),
        low: 0,
        high: 1000,
        chartPadding: {
          top: 0,
          right: 0,
          bottom: 0,
          left: 0
        }
      }
    };
  }

  async componentDidMount() {
    await this.fetchTrails(0, this.state.rowsPerPage, this.props)
    await this.fetchStats(this.props)
  }

  async UNSAFE_componentWillReceiveProps(props) {
    this.fetchTrails(0, this.state.rowsPerPage, props)
    this.fetchStats(props)
  }

  fetchStats = async (props) => {
    const { sponsor } = props;
    if (!sponsor || !sponsor.id) return;
    let fetched_data = await api.get("api/v1/sponsors/" + sponsor.id + "/trails-stats");
    // console.log("data: %o", fetched_data)

    let labels = fetched_data.data.data.stats.map((item, i) => {
      /* { year : 2018, trial_count: 10 } */
      // console.log("item: %o, i:%o", item, i)
      return item["year"]
    })

    let series = fetched_data.data.data.stats.map((item, i) => {
      /* { year : 2018, trial_count: 10 } */
      // console.log("item: %o, i:%o", item, i)
      return item["trial_count"]
    })

    console.log("labels: %o, series: %o", labels, series)

    let stats_options = this.state.stats_options;

    stats_options.high = Math.max(...series);
    stats_options.low = Math.min(...series);

    if (stats_options.low < 0) stats_options.low = 0;

    if (stats_options.high < 10) stats_options.high += 1;
    else if (stats_options.high < 100) stats_options.high += 10;
    else if (stats_options.high < 1000) stats_options.high += 100;

    this.setState({
      stats: {
        labels: labels,
        series: [series],
      }
    })
  }

  fetchTrails = async (page, rowsPerPage, props) => {
    const { sponsor } = props;
    if (!sponsor || !sponsor.id) return;
    console.log("fetching table... %o#%o real page: %o, sponsor: %o", page, rowsPerPage, this.state.page, sponsor)
    let size = 0
    let end = (page + 1) * rowsPerPage
    if (end > size) end = size
    let fetch_params = {
      offset: page * rowsPerPage,
      limit: rowsPerPage,
      sponsor_id: sponsor.id,
    }
    if (this.state.trial_order_by >= 0) {
      fetch_params["order_by"] = trial_header_order_by_keys[this.state.trial_order_by]
      if (this.state.trial_order == 'desc') {
        fetch_params["order_by"] = '-' + fetch_params["order_by"]
      }
    }
    let fetched_data = await api.get("api/v1/trials", fetch_params)
    console.log("data: %o", fetched_data)

    let user_datas = fetched_data.data.data.trials.map((item, i) => {
      // console.log("item: %o, i:%o", item, i)
      let study_first_submitted_date = item["study_first_submitted_date"]
      let date = null
      // console.log("study_first_submitted_date: %o", study_first_submitted_date)
      if (study_first_submitted_date != null && study_first_submitted_date["Valid"]) {
        date = study_first_submitted_date["Time"].substr(0, 10)
      }
      let nctid = <a href={"https://clinicaltrials.gov/ct2/show/study/" + item["nctid"]}>
        <span>{item["nctid"]}</span>
      </a>
      let user_data = [
        nctid, // + link
        item["brief_title"],
        date,
        item["status"]
      ]
      return user_data
    })

    console.log("user_datas: %o | total: %o", user_datas, fetched_data.data.data.total)
    this.setState({
      trial_data: user_datas,
      trial_count: fetched_data.data.data.total,
      page: page,
      rowsPerPage: rowsPerPage
    })
  }

  handleClickRow = (event, id) => {
    // https://material-ui.com/demos/tables/
    console.log("click row: ev:%o, id: %o", event, id)
  };

  handleClickButton = (event, id) => {
    // https://material-ui.com/demos/tables/
    console.log("click button: ev:%o, id: %o", event, id)
  };

  handleChangePage = (event, page) => {
    // console.log("handleChangePage: ev:%o, id: %o", event, page)
    this.fetchTrails(page, this.state.rowsPerPage, this.props)
  };

  handleChangeRowsPerPage = event => {
    let newRowsPerPage = event.target.value
    // this.setState({rowsPerPage: event.target.value })
    // console.log("handleChangeRowsPerPage: ev:%o // %o", event, newRowsPerPage)
    this.fetchTrails(this.state.page, newRowsPerPage, this.props)
  };

  createHeaders = (names, order_by = -1, order = 'asc') => {
    return names.map((prop, key) => {
      // if (key != order_by) return <a onClick={this.handleHeaderClick(key)}>{names[key]}</a>;
      // console.log("CREATE HEADER: prop: %o, key: %o, value: %o, order: %o ", prop, key, names[key], order);
      return <TableSortLabel
        active={key == order_by}
        direction={order}
        onClick={this.handleHeaderClick(key)}
      >
        {names[key]}
      </TableSortLabel>;
    });
  }

  handleHeaderClick = (key) => (event) => {
    let order_by = this.state.trial_order_by;
    let order = this.state.trial_order;
    let new_order_by = key;
    let new_order = 'asc';
    if (order_by === new_order_by) {
      new_order = order === 'asc' ? 'desc' : 'asc';
    }
    console.log("handleHeaderClick: %o %o | %o, %o", key, event, new_order_by, new_order);
    this.setState({
      trial_order_by: new_order_by,
      trial_order: new_order,
      trial_headers: this.createHeaders(trial_header_names, new_order_by, new_order),
    });
    this.fetchTrails(this.state.page, this.state.rowsPerPage, this.props);
  };

  render() {
    const { classes, sponsor } = this.props;
    return (<GridContainer>
      <GridItem xs={12} sm={4} md={4}>
        <GridItem xs={12} sm={12} md={12}>
          <Card profile>
            <CardAvatar profile>
              <a href="#pablo" onClick={e => e.preventDefault()}>
                <img src={avatar} alt="..." />
              </a>
            </CardAvatar>
            <CardBody profile>
              <h4 className={classes.cardTitle}>{sponsor.name}</h4>
              {/* <p className={classes.description}>
                TODO(yu): Add company description here.
              </p> */}
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={12}>
          <Card profile>
            <CardHeader color="primary">
              <h4 className={classes.cardTitleWhite}>Similar Companies</h4>
              <p className={classes.cardCategoryWhite}>
              </p>
            </CardHeader>
            <CardBody profile>
              <Table
                tableHeaderColor="primary"
                tableHead={["ID", "Name", "Description", "Link"]}
                tableData={[
                  ["23937", "Mustafakemalpasa State Hospital", "Example", "#"],
                ]}
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridItem>
      <GridItem xs={12} sm={8} md={8}>
        <GridItem xs={12} sm={12} md={12}>
          <Card chart>
            {/* of trials by year */}
            <CardHeader color="info">
              <ChartistGraph
                className="ct-chart"
                data={this.state.stats}
                type="Bar"
                options={this.state.stats_options}
                responsiveOptions={completedTasksChart.responsiveOptions}
                listener={completedTasksChart.animation}
              />
            </CardHeader>
            <CardFooter chart>
              <p className={classes.cardCategory}># of trials by year</p>
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardBody>
              {/* trials table */}
              <Table
                tableHeaderColor="primary"
                tableHead={this.state.trial_headers}
                tableData={this.state.trial_data}
                handleClickRow={this.handleClickRow}
              />
              <TablePagination
                component="div"
                count={this.state.trial_count}
                rowsPerPage={this.state.rowsPerPage}
                page={this.state.page}
                onPageChange={this.handleChangePage}
                onRowsPerPageChange={this.handleChangeRowsPerPage}
                rowsPerPageOptions={this.state.rowsPerPageOptions}
                ActionsComponent={TablePaginationActions}
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridItem>
    </GridContainer>);
  }
}

export default withStyles(style)(CompanyDetailPage);
