import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import CustomInput from "components/CustomInput";
import Button from "components/CustomButtons";

import axios from "axios";
import qs from "qs";
const FormData = require('form-data')
import { api } from 'config.js';
const path = require('path');

const styles = {
    cardCategoryWhite: {
        color: "rgba(255,255,255,.62)",
        margin: "0",
        fontSize: "14px",
        marginTop: "0",
        marginBottom: "0"
    },
    cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none"
    }
};

class JumpMaker extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            url: "",
            slug: "",
            prompt: "",
        };
    }

    concatUrl(url) {
        // let api_base = this.api_base;
        // let newUrl = `${api_base}${url}`;
        let newUrl = `${api.host}${path.join(api.path, url)}`
        console.log("concat url: %o", newUrl)
        return newUrl
    }

    handleSlugChange = (event) => {
        return this.handleChange(this.state.url, event.target.value);
    }

    handleUrlChange = (event) => {
        return this.handleChange(event.target.value, this.state.slug);
    }

    handleChange = (url, slug) => {
        this.setState({
            url: url,
            slug: slug,
        });
    }

    handleSubmit = (event) => {
        event.preventDefault();
        console.log("submitting: %o", this.state);
        let { url, slug } = this.state;
        let bodyFormData = new FormData();
        bodyFormData.append('url', url);
        if (slug) {
            bodyFormData.append('slug', slug);
        }
        bodyFormData.forEach((value, key) => {
            console.log("form data: %o, %o", key, value
            );
        });
        console.log(bodyFormData);
        axios({
            method: 'post',
            url: this.concatUrl("s"),
            data: bodyFormData,
            headers: {
                "Content-Type": "multipart/form-data",
            },
            transformResponse: [(response) => (JSON.parse(response))],
        }).then((response) => {
            console.log("response: %o", response);
            if (response.data && response.data.short_url) {
                this.setState({
                    prompt: `Your short url is: ${response.data.short_url}`,
                });
            }
            // console.log(response);
            // this.setState({
            //     prompt: response.data,
            // });
        }).catch((error) => {
            console.log("got error: %o", error);
            // is data in error.response.data?
            let data = error.response.data;
            if (data) {
                console.log("data: %o", data);
                let code = "";
                if (data.hasOwnProperty("code")) {
                    code = data.code;
                }
                if (data.hasOwnProperty("error")) {
                    if (code) {
                        this.setState({
                            prompt: `${code}: ${data.error}`,
                        });
                    } else {
                        this.setState({
                            prompt: data.error,
                        });
                    }
                } else {
                    this.setState({
                        prompt: "Unknown error",
                    });
                }
            }
        });
    }

    render() {
        return (
            <div>
                <GridContainer>
                    <GridItem xs={12} sm={12} md={5}>
                        <CustomInput
                            labelText="URL to shorten (required)"
                            id="url"
                            formControlProps={{
                                fullWidth: true
                            }}
                            inputProps={{
                                onChange: this.handleUrlChange,
                                value: this.state.url,
                            }}
                        />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={1}>
                        <CustomInput
                            labelText="Slug (optional)"
                            id="slug"
                            formControlProps={{
                                fullWidth: true
                            }}
                            inputProps={{
                                onChange: this.handleSlugChange,
                                value: this.state.slug,
                            }}
                        />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={2}>
                        <Button
                            color="primary"
                            onClick={this.handleSubmit}
                        >Shorten</Button>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12}>
                        <h3>
                            {this.state.prompt}
                        </h3>
                    </GridItem>
                </GridContainer>
            </div>
        );
    }
}

export default withStyles(styles)(JumpMaker);
