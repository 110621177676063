import React, { useState } from 'react';
import withStyles from "@material-ui/core/styles/withStyles";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import InputBase from '@material-ui/core/InputBase';
import SimpleBorad from "components/SimpleBorad";
import { stemmer } from 'stemmer';

import { conversations } from "dialogue.json";

const myStyle = (theme) => ({
  root: {
    width: '100%',
    // maxWidth: '36ch',
    backgroundColor: theme.palette.background.paper,
    width: "100%",
    marginTop: "10px",
    padding: "30px 15px",
    minHeight: "calc(100vh - 300px)",
  },
  conversations: {
    width: '100%',
    minHeight: "calc(100vh - 300px)",
  },
  content: {
    width: "100%",
  },
  inline: {
    display: 'inline',
  },
  orange: {
    color: "white",
    backgroundColor: "orange",
  },
  blue: {
    color: "white",
    backgroundColor: "blue",
  },
  inputRoot: {
    width: "100%",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    fullWidth: true, // TODO: fix this
  },
});


function ToConversation(key, author, content, props) {
  const { classes } = props;
  let avatar = author === "AI" || author == "A" ? <Avatar className={classes.orange}>AI</Avatar> : <Avatar className={classes.blue}>You</Avatar>;
  return <div key={key}>
    <ListItem alignItems="flex-start">
      <ListItemAvatar>
        {avatar}
      </ListItemAvatar>
      <ListItemText
        primary=""
        secondary={<SimpleBorad content={content} />}
      />
    </ListItem>
    <Divider variant="inset" component="li" />
  </div>
}


function PreprocessText(text) {
  text = text.replace(/[^a-zA-Z0-9 ]/g, " ");
  return stemmer(text);
}

function SimScore(query, text) {
  query = PreprocessText(query);
  text = PreprocessText(text);
  let queryWords = query.split(" ");
  let textWords = text.split(" ");
  // filter and remove empty and duplicate words
  queryWords = queryWords.filter((word, index) => {
    return word !== "" && queryWords.indexOf(word) === index;
  });
  textWords = textWords.filter((word, index) => {
    return word !== "" && textWords.indexOf(word) === index;
  });
  let count = 0;
  console.log("q: %o, t: %o", queryWords, textWords)
  for (let i = 0; i < queryWords.length; i++) {
    let queryWord = queryWords[i];
    for (let j = 0; j < textWords.length; j++) {
      let textWord = textWords[j];
      if (queryWord === textWord) {
        count++;
        break;
      }
    }
  }
  // calculate the percentage of words in query that are in text
  return count / queryWords.length;
}

class ConversationList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentValue: "",
      disabled: false,
      myConversation: [
        {
          "author": "AI",
          "content": "Hi, I am Trialpedia, how can I help you today?"
        }
      ]
    };
  }

  handleSearchQueryFinished = (event) => {
    console.log("ChatPage: handleSearchQueryFinished: %o", event.target.value);
    let newConversation = this.state.myConversation;
    newConversation.push({
      "author": "You",
      "content": event.target.value
    });

    this.setState({
      myConversation: newConversation,
      currentValue: "",
      disabled: true,
    });

    let question = event.target.value;

    // execute makeResponse after 3 seconds
    setTimeout(() => {
      this.makeResponse(question);
      this.setState({
        disabled: false,
      });
    }, 1000);
  }


  makeResponse = (question) => {
    let newConversation = this.state.myConversation;
    // filter the conversation
    let MaxIndex = -1;
    let MaxScore = 0;
    for (let i = 0; i < conversations.length; i++) {
      let conversation = conversations[i];
      let score = SimScore(question, conversation["Q"]);
      if (score > 0.5 && score > MaxScore) {
        MaxScore = score;
        MaxIndex = i;
        if (MaxScore > 0.9) {
          break;
        }
      }
    }
    if (MaxIndex > -1) {
      newConversation.push({
        "author": "AI",
        "content": conversations[MaxIndex]["A"]
      });
    } else {
      newConversation.push({
        "author": "AI",
        "content": "Sorry, I don't understand what you are asking."
      });
    }
    this.setState({
      myConversation: newConversation,
      currentValue: "",
      disabled: false,
    });
  }

  handleContentChange = (event) => { // do nothing for now
    console.log("ChatPage: handleSearchQueryChange: %o", event.target.value);
    this.setState({
      currentValue: event.target.value
    });
  }

  confirmEnter = (e) => {
    if (e.key === 'Enter') {
      this.handleSearchQueryFinished(e);
    }
  }

  render() {
    const { classes } = this.props;
    return (<div className={classes.root}>
      <List className={classes.conversations}>
        {this.state.myConversation.map((conversation, index) => {
          return ToConversation(index, conversation.author, conversation.content, this.props);
        })}
      </List>
      <div className={classes.search}>
        <InputBase
          placeholder="Ask Here…"
          multiline={true}
          classes={{
            root: classes.inputRoot,
            input: classes.inputInput,
          }}
          inputProps={{
            disabled: this.state.disabled,
            onKeyPress: this.confirmEnter,
            value: this.state.currentValue,
            onChange: this.handleContentChange,
            'aria-label': 'Ask Here'
          }}
        />
      </div>
    </div>);
  }
};

export default withStyles(myStyle)(ConversationList);
