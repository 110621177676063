import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

import CompanySideBar from "components/SearchSideBar/CompanySideBar.jsx";
import TrialSideBar from "components/SearchSideBar/TrialSideBar.jsx";

const styles = {
};

function SearchSideBar(props) {
  const { classes, searchBy, ...rest } = props;
  if (searchBy == "company") {
    return <CompanySideBar searchBy={searchBy} {...rest} />;
  } else {
    return <TrialSideBar searchBy={searchBy} {...rest} />;
  }
}

export default withStyles(styles)(SearchSideBar);
