import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import remarkGfm from 'remark-gfm';
import ReactMarkdown from 'react-markdown';

const styles = {
    root: {
        maxWidth: "100%",
        width: "100vw",
        color: "black",
    },
};


class SimpleBoard extends React.Component {
    render() {
        const { classes, content } = this.props;
        return (<div className={classes.root}>
            <ReactMarkdown children={content} remarkPlugins={[remarkGfm]} />
        </div>);
    }
}


export default withStyles(styles)(SimpleBoard);
