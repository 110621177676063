import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// @material-ui/icons
import Store from "@material-ui/icons/Store";
import Info from "@material-ui/icons/Info";
import Warning from "@material-ui/icons/Warning";
import DateRange from "@material-ui/icons/DateRange";
import LocalOffer from "@material-ui/icons/LocalOffer";
import Update from "@material-ui/icons/Update";
import ArrowUpward from "@material-ui/icons/ArrowUpward";
import AccessTime from "@material-ui/icons/AccessTime";
import Accessibility from "@material-ui/icons/Accessibility";
import BugReport from "@material-ui/icons/BugReport";
import Code from "@material-ui/icons/Code";
import Cloud from "@material-ui/icons/Cloud";
// core components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import Table from "components/Table";
import Danger from "components/Typography/Danger.jsx";
import Card from "components/Card";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardFooter from "components/Card/CardFooter.jsx";

import qs from "qs";

const styles = {
    cardCategory: {
        color: "#999999",
        margin: "0",
        fontSize: "14px",
        marginTop: "0",
        paddingTop: "10px",
        marginBottom: "0"
    },
    cardCategoryWhite: {
        color: "rgba(255,255,255,.62)",
        margin: "0",
        fontSize: "14px",
        marginTop: "0",
        marginBottom: "0"
    },
    cardTitle: {
        justifyContent: 'space-between',
        color: "#3C4858",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        textAlign: 'left',
        "& small": {
            color: "#777",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
    cardItem: {
        justifyContent: 'space-between',
        color: "#3C4858",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "100",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        textAlign: 'left',
        "& small": {
            color: "#777",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
    cardTagItem: {
        justifyContent: 'space-between',
        color: "#3C4858",
        marginTop: "0px",
        paddingRight: "5px",
        minHeight: "auto",
        fontWeight: "100",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        textAlign: 'center',
        display: 'inline-block',
        "& small": {
            color: "#777",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
    cardIcon: {
        width: "15px",
        height: "15px",
        color: "#ac92ec",
    },
};

function ToQueryPart(props) {
    let { parsedQS } = props;
    let prevQuery = parsedQS.qs;
    let prevBy = parsedQS.by;
    let newParams = Object.assign({});
    newParams.qs = prevQuery;
    newParams.by = prevBy;
    let queryPart = qs.stringify(newParams);
    return queryPart;
}

function CompanyList(props) {
    const { classes, searchBy, sponsors, total } = props;

    if (searchBy != 'company') {
        console.log("search by : %o", searchBy)
        return <div />
    }

    if (sponsors == null || sponsors.length === 0) {
        return <div />
    }

    let queryPart = ToQueryPart(props);

    const tableData = sponsors.map((company) => {
        // filter out empty disease groups
        let trial_dz_groups = company.trial_dz_groups.filter((disease) => {
            return !(disease.length == 0 || disease == null || disease == "Unknown")
        })

        let trial_dz_groups_count_limit = 20
        // take only first 5 disease groups
        if (trial_dz_groups.length > trial_dz_groups_count_limit) {
            trial_dz_groups = trial_dz_groups.slice(0, trial_dz_groups_count_limit)
        }

        trial_dz_groups = trial_dz_groups.map((disease) => {
            return <div key={company.id + "_" + "tag" + "_" + disease} className={classes.cardTagItem} >
                <LocalOffer className={classes.cardIcon} /> {disease}
            </div>
        })

        let trial_brief_titles = company.trial_brief_titles.filter((title) => {
            return !(title.length == 0 || title == null || title == "Unknown")
        })

        let trial_brief_titles_count_limit = 3

        let trial_brief_titles_has_more = false

        if (trial_brief_titles.length > trial_brief_titles_count_limit) {
            trial_brief_titles = trial_brief_titles.slice(0, trial_brief_titles_count_limit)
            trial_brief_titles_has_more = true
        }

        trial_brief_titles = trial_brief_titles.map((title) => {
            return <li key={company.id + "_" + "title" + "_" + title} className={classes.cardItem} >
                {title}
            </li>
        })

        if (trial_brief_titles_has_more) {
            // append a "more" link
            trial_brief_titles.push(<li key={company.id + "_" + "title" + "_" + "more"} className={classes.cardItem} >...</li>)
        }

        return <Card key={company.id}>
            <CardHeader color="info" stats icon>
                <p className={classes.cardCategory}>Trial Count: {company.trial_count}</p>
                <h3 className={classes.cardTitle}>
                    <a href={"/companies/" + company.id + "?" + queryPart}>
                        {company.name}
                    </a>
                </h3>
            </CardHeader>
            <CardBody>
                <ul className={classes.stats}>
                    {trial_brief_titles}
                </ul>
            </CardBody>
            <CardFooter stats>
                <div className={classes.stats}>
                    <div>{trial_dz_groups}</div>
                </div>
            </CardFooter>
        </Card>
    });

    return (
        <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
                {tableData}
            </GridItem>
        </GridContainer>
    );
}

export default withStyles(styles)(CompanyList);
