// import Examples from "layouts/Examples";
// import HelloWorld from "layouts/HelloWorld";
import Search from "views/Search";
import Home from "views/Home";
import Trial from "views/Trial";
import Company from "views/Company";
import Chat from "views/Chat";

// please notice the sequence & path
const indexRoutes = [
    {
        path: "/search",
        component: Search,
    },
    {
        path: "/chat",
        component: Chat,
    },
    {
        path: "/home",
        component: Home,
    },
    {
        path: "/companies",
        component: Company,
    },
    {
        path: "/trials",
        component: Trial,
    },
    {
        redirect: true,
        path: "/",
        to: "/home",
    },
];

export default indexRoutes;
