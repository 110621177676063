import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import CustomInput from "components/CustomInput";
import Button from "components/CustomButtons";

import axios from "axios";
import qs from "qs";
const FormData = require('form-data')
import { api } from 'config.js';
import { Redirect } from "react-router";
const path = require('path');

const styles = {
    input: {
        width: "100%",
    },
    button: {
        margin: "8px",
        height: "80%",
        width: "90%",
    },
};

class SearchBox extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            searchBy: props.searchBy,
            query: "",
            prompt: "",
            jumpTo: "",
        };
    }

    handleQueryChange = (event) => {
        return this.handleChange(event.target.value); // this.state.slug
    }

    handleChange = (query) => {
        this.setState({
            query: query,
        });
    }

    confirmEnter = (e) => {
        if (e.key === 'Enter') {
            this.handleSubmit(e);
        }
    }

    handleSubmit = (event) => {
        event.preventDefault();
        console.log("submitting: %o", this.state);
        let query = this.state.query;
        if (query.length === 0) {
            this.setState({
                prompt: "Please type something to search",
            });
            console.log("no query");
            return
        }
        let queryPart = qs.stringify({ qs: query, by: this.state.searchBy });
        let url = "/search?" + queryPart;
        console.log("url: %o", url);
        this.setState({
            jumpTo: url,
        });
    }

    render() {
        if (this.state.jumpTo.length > 0) {
            return <Redirect to={this.state.jumpTo} />
        }
        return (
            <div>
                <GridContainer>
                    <GridItem xs={12} sm={12} md={9}>
                        <CustomInput
                            labelText={"Type something to search " + this.state.searchBy}
                            id="query"
                            formControlProps={{
                                fullWidth: true
                            }}
                            inputProps={{
                                onChange: this.handleQueryChange,
                                onKeyPress: this.confirmEnter,
                                value: this.state.query,
                            }}
                            styles={styles.input}
                        />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={3}>
                        <Button
                            color="primary"
                            onClick={this.handleSubmit}
                            className={this.props.classes.button}
                        >Search</Button>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12}>
                        <h3>
                            {this.state.prompt}
                        </h3>
                    </GridItem>
                </GridContainer>
            </div>
        );
    }
}

export default withStyles(styles)(SearchBox);
