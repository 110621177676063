import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import InputLabel from "@material-ui/core/InputLabel";
import Code from "@material-ui/icons/Code";
import Cloud from "@material-ui/icons/Cloud";
import Polymer from "@material-ui/icons/Polymer";
// core components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import CustomInput from "components/CustomInput";
import Card from "components/Card";
import CardBody from "components/Card/CardBody.jsx";
import Chip from '@material-ui/core/Chip';
import Paper from '@material-ui/core/Paper';

import qs from "qs";


const useStyles = (theme) => ({
    root: {
        display: 'flex',
        justifyContent: 'center',
        flexWrap: 'wrap',
        listStyle: 'none',
        margin: 0.5,
        padding: theme.spacing(1),
    },
    container: {
        display: 'flex',
        flexWrap: 'wrap',
        padding: theme.spacing(0.5),
    },
    chipRoot: {
        display: 'flex',
        flexWrap: 'wrap',
        margin: '10px',
    },
    chipItem: {
        justifyContent: 'space-between',
        color: "#3C4858",
        marginTop: "0px",
        paddingRight: "5px",
        minHeight: "auto",
        fontWeight: "100",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        textAlign: 'center',
        display: 'inline-block',
        "& small": {
            color: "#777",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
});

function CompanySideBar(props) {
    const { classes, aggs, parsedQS, handleFilterEventChange } = props;
    console.log("aggs: %o", aggs);
    let trial_dz_groups = aggs.trial_dz_groups;
    let trial_dz_groups_list = [];

    let queryDzGroup = parsedQS.dz_group;

    let handleFilterClickEvent = (category, key, active) => {
        console.log("handleChipClick: %o :: %o", category, key, active);
        if (handleFilterEventChange) {
            handleFilterEventChange(category, key, active);
        }
    }

    if (trial_dz_groups && trial_dz_groups.buckets) {
        let buckets = trial_dz_groups.buckets.filter((item) => {
            return !(item == null || item.key.length == 0 || item.key == "Unknown")
        })
        trial_dz_groups_list = buckets.map((item) => {
            let is_active = queryDzGroup == item.key;
            let color = "default";
            let variant = "outlined";
            if (is_active) {
                color = "primary";
                variant = "default";
            }
            return <div key={item.key} className={classes.chipItem}>
                <Chip variant={variant} color={color} label={item.key + ": " + item.doc_count} clickable={true} onClick={e => handleFilterClickEvent("dz_group", item.key, !is_active)} />
            </div>
        });
    }

    return (
        <Paper className={classes.root}>
            <GridContainer className={classes.container}>
                <GridItem xs={12} sm={12} md={12}>
                    <p>Filter by Disease</p>
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                    <div className={classes.chipRoot}>
                        {trial_dz_groups_list}
                    </div>
                </GridItem>
            </GridContainer>
        </Paper>
    );
}

export default withStyles(useStyles)(CompanySideBar);
