import Cookies from 'universal-cookie'

const cookies = new Cookies()

export function GetCookie(name, options = {}) {
    return cookies.get(name, options)
}

export function GetAllCookies(options = {}) {
    return cookies.getAll(options)
}

export function SetCookie(name, value, options = { path: '/' }) {
    return cookies.set(name, value, options)
}

export function RemoveCookie(name, options) {
    return cookies.remove(name, options)
}
