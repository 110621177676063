import React from "react";
import classNames from "classnames";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// @material-ui/icons
import Code from "@material-ui/icons/Code";
import Polymer from "@material-ui/icons/Polymer";
// core components
import CustomTabs from "components/CustomTabs";
import SearchBox from "components/SearchBox/index";

import { title } from "config.js";

const configTitle = title;

const style = {
  container: {
    height: "100vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  form: {
    width: "50%",
    height: "60vh",
  },
};


class HomePage extends React.Component {
  constructor(props) {
    super(props)
  }

  componentDidMount() {
    this._ismounted = true
  }

  componentWillUnmount() {
    this._ismounted = false
  }


  render() {
    // const { classes } = this.props;
    const { classes, color, baseUrl } = this.props;

    const containerClasses = classNames({
      [" " + classes[color]]: color
    });

    return (<div className={classes.container + containerClasses}>
      <div className={classes.form}>
        <CustomTabs
          title={configTitle}
          headerColor="primary"
          tabs={[
            {
              tabName: "Search by Company",
              tabIcon: Polymer,
              tabContent: (<SearchBox searchBy="company" />)
            },
            {
              tabName: "Search by Trial",
              tabIcon: Code,
              tabContent: (<SearchBox searchBy="trial" />)
            }
          ]}
        />
      </div>
    </div>);
  }
}

export default withStyles(style)(HomePage);
