import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import CustomInput from "components/CustomInput";

import { api } from 'config.js';

const styles = {
    cardCategoryWhite: {
        color: "rgba(255,255,255,.62)",
        margin: "0",
        fontSize: "14px",
        marginTop: "0",
        marginBottom: "0"
    },
    cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none"
    }
};

class CommandForm extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            host: api.host,
            url: "",
            slug: "",
            prompt: "",
        }
    }

    handleSlugChange = (event) => {
        return this.handleChange(this.state.url, event.target.value);
    }

    handleUrlChange = (event) => {
        return this.handleChange(event.target.value, this.state.slug);
    }


    handleChange = (url, slug) => {
        let { host } = this.state;
        if (host.length < 1) {
            // get current scheme + host
            host = window.location.protocol + "//" + window.location.host;
        }
        let prompt = `curl -X POST ${host}/s -F "url=${url}"`;
        if (slug) {
            prompt += ` -F "slug=${slug}"`;
        }
        if (!url.startsWith("https://") && !url.startsWith("http://")) {
            prompt = "Invalid URL: must start with http:// or https://";
        }
        this.setState({
            url: url,
            slug: slug,
            prompt: prompt,
        });
    }


    render() {
        return (
            <div>
                <GridContainer>
                    <GridItem xs={12} sm={12} md={5}>
                        <CustomInput
                            labelText="URL to shorten (required)"
                            id="url"
                            formControlProps={{
                                fullWidth: true
                            }}
                            inputProps={{
                                onChange: this.handleUrlChange,
                                value: this.state.url,
                            }}
                        />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={1}>
                        <CustomInput
                            labelText="Slug (optional)"
                            id="slug"
                            formControlProps={{
                                fullWidth: true
                            }}
                            inputProps={{
                                onChange: this.handleSlugChange,
                                value: this.state.slug,
                            }}
                        />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12}>
                        <h3>
                            {this.state.prompt}
                        </h3>
                    </GridItem>
                </GridContainer>
            </div>
        );
    }
}

export default withStyles(styles)(CommandForm);
