import React from "react";
import classNames from "classnames";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// @material-ui/icons
import Code from "@material-ui/icons/Code";
import Cloud from "@material-ui/icons/Cloud";
import Polymer from "@material-ui/icons/Polymer";
// core components
import CommandForm from "components/CommandForm";
import CustomTabs from "components/CustomTabs";
import SearchBox from "components/SearchBox";
import SearchContent from "components/SearchContent";

import Header from "components/Header/index";
import Footer from "components/Footer";
import ConversationList from "components/ConversationList";

import searchStyle from "assets/jss/material-dashboard-react/layouts/searchStyle.jsx";

import { api } from 'config.js';
import { title } from "config.js";
import qs from "qs";
import { Redirect } from "react-router";
import axios from "axios";
const path = require('path');

const mySearchStyle = searchStyle;

class ChatPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sponsors: [],
      trials: [],
      aggs: {},
      total: 0,
      message: "",
      searchBy: "company",
    };
  }

  concatUrl(url) {
    let newUrl = `${api.host}${path.join(api.path, url)}`
    console.log("concat url: %o", newUrl)
    return newUrl
  }

  componentDidMount() {
  }

  handleQueryChangeEvent = (newQuery, newBy, params = {}) => {
    newQuery = newQuery.trim();
    newBy = newBy.trim();
    let parsedQS = qs.parse(this.props.location.search, { ignoreQueryPrefix: true });
    let prevQuery = parsedQS.qs;
    let prevBy = parsedQS.by;
    if (newQuery.length === 0) {
      newQuery = prevQuery;
    }
    if (newBy.length === 0) {
      newBy = this.state.searchBy;
    }
    if (newQuery === prevQuery && newBy === prevBy && Object.keys(params).length === 0) {
      console.log("ChatPage: no change");
      return;
    }
    // copy params
    let newParams = Object.assign({}, params);
    newParams.qs = newQuery;
    newParams.by = newBy;
    console.log("ChatPage: handleQueryChangeEvent: %o", newParams);
    let queryPart = qs.stringify(newParams);
    let url = "/search?" + queryPart;
    console.log("url: %o", url);
    this.props.history.push(url);
  }

  handleSearchQueryChange = (event) => { // do nothing for now
    console.log("ChatPage: handleSearchQueryChange: %o", event.target.value);
  }

  handleSearchQueryFinished = (event) => {
    console.log("ChatPage: handleSearchQueryFinished: %o", event.target.value);
    let newQuery = event.target.value;
    this.handleQueryChangeEvent(newQuery, ""); // "" means no change
  }

  handleSearchByChange = (event) => {
    console.log("ChatPage: handleSearchQueryFinished: %o", event.target.value);
    // let newBy = event.target.value;
    // this.handleQueryChangeEvent("", newBy); // "" means no change
    this.setState({ searchBy: event.target.value });
  }

  render() {
    // const { classes } = this.props;
    const { classes, color, baseUrl } = this.props;

    let parsedQS = qs.parse(this.props.location.search, { ignoreQueryPrefix: true });

    const containerClasses = classNames({
      [" " + classes[color]]: color
    });

    if (!(parsedQS.by == "company" || parsedQS.by == "trial")) {
      parsedQS.by = "company";
    }

    console.log("ChatPage: parsedQS: %o @ %o", parsedQS.qs, parsedQS.by);

    console.log("search by %o, sponsors: %o, trials: %o", parsedQS.by, this.state.sponsors, this.state.trials)

    return (<div className={classes.wrapper}>
      <div className={classes.mainPanel} ref="mainPanel">
        {/* Header */}
        <Header
          handleSearchQueryChange={this.handleSearchQueryChange}
          handleSearchQueryFinished={this.handleSearchQueryFinished}
          handleSearchByChange={this.handleSearchByChange}
          parsedQS={parsedQS}
          searchBy={this.state.searchBy}
          currentValue={parsedQS.qs}
        />
        {/* Content */}
        <div className={classes.content}>
          <ConversationList />
        </div>
        {/* Footer */}
        <Footer />
      </div>
    </div>);
  }
}

export default withStyles(mySearchStyle)(ChatPage);

